<template>
  <el-dialog
    :append-to-body="true"
    :visible="dialogShow"
    :before-close="beforeCloseHandle"
    :title="isAdd ? '新增渠道' : '编辑渠道'"
    width="600px"
    class="pay-add-or-edit-dialog"
  >
    <el-form
      ref="form"
      :model="form"
      :rules="rules"
      size="small"
      label-width="122px"
      hide-required-asterisk
    >
<!--      新建默认选全部-->
      <el-form-item prop="lang" label="语言">
        <el-radio
            v-model="form.lang"
            label="all"
        >全部</el-radio>
        <el-radio
            v-model="form.lang"
            label="zh_CN"
        >中文</el-radio>
        <el-radio
            v-model="form.lang"
            label="zh_Hant"
        >繁体中文</el-radio>
        <el-radio
            v-model="form.lang"
            label="en_US"
        >英文</el-radio>
      </el-form-item>
      <el-form-item
          prop="channelName"
          label="渠道名称"
      >
        <el-input
          placeholder="允许中、英文和数字,限制20个字符以内"
          v-model="form.channelName"
        />
      </el-form-item>
      <el-form-item
          prop="channelNameHant"
          label="繁体名称"
      >
        <el-input
            placeholder="允许中、英文和数字,限制20个字符以内"
            v-model="form.channelNameHant"
        />
      </el-form-item>
      <el-form-item
          prop="channelNameEn"
          label="英文名称"
      >
        <el-input
          placeholder="允许字母数字空格限制20个字符以内"
          v-model="form.channelNameEn"
          @input="channelNameEnInputHandle"
          maxLength="20"
        />
      </el-form-item>

      <el-form-item prop="status" label="状态">
        <el-radio v-model="form.status" label="1">启用</el-radio>
        <el-radio v-model="form.status" label="2">禁用</el-radio>
      </el-form-item>
      <el-form-item label="渠道类型">
        <el-radio :disabled="typeDisabled" v-model="form.type" label="2">出金</el-radio>
        <el-radio :disabled="typeDisabled" v-model="form.type" label="1">入金</el-radio>
        <el-radio :disabled="typeDisabled" v-model="form.type" label="3">出佣</el-radio>
      </el-form-item>
      <el-form-item label="自动入账">
        <el-radio v-model="form.isAutomaticEntry" label="1">是</el-radio>
        <el-radio v-model="form.isAutomaticEntry" label="2">否</el-radio>
      </el-form-item>
      <el-form-item prop="payWay" label="支付方式">
        <el-select v-model="form.payWay" placeholder="请选择">
          <el-option
            v-for="item in payWayOptionsComputed"
            :key="item.id"
            :label="item.name"
            :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item v-if="onlinePayWayFlag" prop="onlineChannelBusiness" label="渠道商">
        <el-select
          v-model="form.onlineChannelBusiness"
          placeholder="请选择渠道商"
          @change="onlineChannelBusinessChangeHandle"
        >
          <el-option
            v-for="(item,index) in channelInfoList"
            :key="index"
            :label="item.label"
            :value="item.payPlatformName"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item
          v-if="form.type == 1 || form.type == 2"
          label="少数民族风控"
          prop="riskControlFlag"
      >
        <el-radio v-model="form.riskControlFlag" :label="1">限制</el-radio>
        <el-radio v-model="form.riskControlFlag" :label="0">不限制</el-radio>
      </el-form-item>
      <el-form-item v-if="onlinePayWayFlag" prop="currency" label="支付币种">
        <el-select
          v-model="form.currency"
          class="currency-min-select"
        >
          <el-option
            v-for="item in getPaymentCurrencyList()"
            :key="item.currency"
            :label="item.currency"
            :value="item.currency"
          ></el-option>
        </el-select>
<!--        根据渠道商选择和支付币种变化-->
        <span>{{exRate}}</span>
      </el-form-item>
      <el-form-item prop="minMoney" label="最小限额">
        <el-input class="min-money" v-model="form.minMoney"></el-input>
      </el-form-item>
      <el-form-item label="最大限额">
        <el-col :span="5">
          <el-select
            v-model="limitMax"
            class="money-prefix"
            @change="limitMaxChangeHandle"
          >
            <el-option
              v-for="item in limitMaxOption"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-col>
        <el-col :span="14">
          <el-form-item
            prop="maxMoney"
          >
            <el-input
              v-if="limitMax != 1"
              class="max-money"
              placeholder="请输入最大限制金额" v-model="form.maxMoney">
            </el-input>
          </el-form-item>
        </el-col>
      </el-form-item>
      <el-form-item
        label="可用时间段"
        ref="availabletime"
        prop="availableTime"
      >
        <el-time-picker
          is-range
          v-model="form.availableTime"
          range-separator="至"
          start-placeholder="开始时间"
          end-placeholder="结束时间"
          placeholder="选择时间范围"
          value-format="HH:mm:ss"
          format="HH:mm:ss"
        >
        </el-time-picker>
      </el-form-item>

      <!-- 根据不同的东西显示-->
      <template
        v-if="rateFlag"
      >
        <el-form-item prop="onlineRate" label="费率">
          <div class="input-box-rate">
            <el-input placeholder="请输入费率值" v-model="form.onlineRate"></el-input>
            <span class="ratesuffix">%</span>
          </div>
        </el-form-item>
        <el-form-item
                v-if="!(form.payWay == 3 && form.sourceType == 1)"
                prop="onlineSort" label="排序">
        <el-input v-model="form.onlineSort" placeholder=""></el-input>
      </el-form-item>
      </template>
      <template
        v-if="form.payWay == 2"
      >
        <el-form-item prop="bankName" label="Bank Name">
          <el-input v-model="form.bankName"></el-input>
        </el-form-item>
        <el-form-item prop="accountName" label="Account Name">
          <el-input v-model="form.accountName"></el-input>
        </el-form-item>
        <el-form-item prop="BankNumberOrAccount" label="Bank number/account">
          <el-input v-model="form.BankNumberOrAccount"></el-input>
        </el-form-item>
        <el-form-item prop="bankAddress" label="Bank Address">
          <el-input v-model="form.bankAddress"></el-input>
        </el-form-item>
        <el-form-item prop="swiftCode" label="SWIFT CODE">
          <el-input v-model="form.swiftCode"></el-input>
        </el-form-item>
      </template>
      <template
        v-if="form.payWay == 3"
      >
        <el-form-item label="来源">
          <el-radio v-model="form.sourceType" :label="1">第三方</el-radio>
          <el-radio v-model="form.sourceType" :label="2">二维码地址</el-radio>
        </el-form-item>
        <el-form-item prop="onlineSort" label="排序">
          <el-input v-model="form.onlineSort" placeholder=""></el-input>
        </el-form-item>
        <el-form-item
          v-if="form.sourceType == 1"
          prop="onlineChannelBusiness"
          label="渠道商"
        >
          <el-select
            v-model="form.onlineChannelBusiness"
            placeholder="请选择渠道商"
            @change="onlineChannelBusinessChangeHandle"
          >
            <el-option
              v-for="(item,index) in channelInfoList"
              :key="index"
              :label="item.label"
              :value="item.payPlatformName"
            ></el-option>
          </el-select>
        </el-form-item>
        <template v-if="form.sourceType == 2">
          <el-form-item label="充币地址" prop="address">
            <el-input v-model="form.address"></el-input>
          </el-form-item>
          <el-form-item
            ref="qrpic"
            label="充币二维码"
            prop="qrPic"
          >
            <el-upload
              class="upload-demo"
              action="''"
              :accept="getLimitUploadImageFormat()"
              :http-request="handleSuccessQrPic"
              :before-upload="beforeAvatarUpload"
              list-type="picture"
              :show-file-list='false'
            >
              <!--    :on-success="handleSuccessQrPic"-->
              <el-button size="small" type="primary">点击上传</el-button>
            </el-upload>
            <img v-if="form.qrPic" :src="form.qrPic" class="qr-pic">
            <uploadImgTips></uploadImgTips>
          </el-form-item>
        </template>
      </template>
      <el-form-item
        v-if="getIconShow()"
        ref="iconurl"
        prop="icon"
        label="Icon"
      >
        <el-upload
          class="upload-demo"
          action="''"
          :accept="getLimitUploadImageFormat()"
          :http-request="handleSuccessIcon"
          list-type="picture"
          :before-upload="beforeAvatarUpload"
          :show-file-list='false'
        >
<!--          :on-success="handleSuccessIcon"-->
          <el-button size="small" type="primary">点击上传</el-button>
        </el-upload>
        <img v-if="form.icon" :src="form.icon" class="icon-class">
        <uploadImgTips></uploadImgTips>
      </el-form-item>
      <el-form-item
        label="渠道说明"
        prop="desc"
      >
        <quill-editor
            @catchData="catchData"
            :defaultValues="form.desc"
        ></quill-editor>
      </el-form-item>
      <el-form-item
          label="繁体说明"
          prop="descHant"
      >
        <quill-editor
            @catchData="catchDataHant"
            :defaultValues="form.descHant"
        ></quill-editor>
      </el-form-item>
      <el-form-item
          label="英文说明"
          prop="descEn"
      >
        <quill-editor
            @catchData="catchDataEn"
            :defaultValues="form.descEn"
        ></quill-editor>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button
          type="text"
          @click="cancel"
      >取消</el-button>
      <el-button
          :loading="loading"
          type="primary"
          @click.stop="doSubmit"
      >确认</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { base64toFile, getBase64, getLimitUploadImageFormat, limitImageSizeFormat } from '@/utils/index'
import quillEditor from '@/components/tools/quillEditor'
import { getPaymentList } from '@/api/bussetup/paymentManage'
import { getByteLen, validatpositiveInt, limitEnAndSpace } from '@/utils/validate';
import { mapGetters } from 'vuex';
import { addPayFee,editPayFee,queryChannelInfoList } from '@/api/bussetup/payManage';
import { uploadFile } from '@/api/data'
export default {
  components: {
    quillEditor
  },
  props: {
    isAdd: {
      type: Boolean,
      required: true,
    },
    dialogShow:{
      type: Boolean,
      default: false
    },
    payInfo:{
      type: Object,
    },
  },
  data() {
    let _this = this;
    let validateChanelName = (rule,value,callback) => {
      if(!value){
        callback(new Error('请填写渠道名称'));
      }
      setTimeout(() => {
        if(getByteLen(value) > 20){
          callback(new Error('限制20个字符(10个汉字)'));
        }else {
          callback();
        }
      }, 1000);
    };
    let validateMinMoney = (rule,value,callback) => {
      setTimeout(() => {
        if(!validatpositiveInt(value)){
          callback(new Error('请输入正整数'));
        }else {
          callback();
        }
      }, 1000);
    };
    let validateMaxMoney = (rule,value,callback) => {
      if(_this.limitMax == 1){
        callback();
      }else {
        if(!value){
          return callback(new Error('请填写最大限额'));
        }
        setTimeout(() => {
          if(!validatpositiveInt(value)){

            callback(new Error('请输入正整数'));
          }else {
            if((value - 0) < (_this.form.minMoney -0 )){
              return callback(new Error('请输入比最小值大的正整数'));
            }
            callback();
          }
        }, 1000);
      }
    };
    return {
      channelInfoList: [],
      loading: false,
      exRate: '',
      form: {
        id: '',
        channelName: '',
        channelNameHant: '',
        channelNameEn: '',
        lang: 'all',
        status: '1',
        type: '1',
        isAutomaticEntry: '1',
        minMoney: '',
        currency: '',
        maxMoney: '',
        availableTime: [],
        payWay: 1,

        // 在线支付的条件下
        onlineRate: '',
        onlineChannelBusiness: '',
        riskControlFlag: 0,  //默认勾选不限制
        onlineSort: '',
        icon: '',

        // 电汇支付的条件下
        bankName: '',
        accountName: '',
        BankNumberOrAccount: '',
        bankAddress: '',
        swiftCode: '',
        // USDT 支付
        sourceType: 1,
        address: '',
        qrPic: '',
        desc: '',
        descEn: '',
        descHant: '',
      },

      rules:{
        channelName:[
          { validator: validateChanelName, trigger: 'blur' },
        ],
        channelNameHant:[
          { validator: validateChanelName, trigger: 'blur' },
        ],
        channelNameEn:[
          { required: true, message: '请输入渠道英文名称', trigger: 'blur' },
        ],
        status:[
          { required: true, message: '选择渠道状态', trigger: 'change' },
        ],
        type:[
          { required: true, message: '请选择渠道类型', trigger: 'change' },
        ],
        minMoney:[
          { required: true, message: '请输入最小限额', trigger: 'blur' },
          { validator: validateMinMoney, trigger: 'blur' },
        ],
        maxMoney:[
          { validator: validateMaxMoney, trigger: 'blur' },
        ],
        availableTime:[
          { required: true, message: '请选择起始时间和结束时间', trigger: 'blur' },
        ],
        payWay:[
          { required: true, message: '请选择支付方式', trigger: 'change' },
        ],
        // 在线支付的方式
        onlineRate:[
          { required: true, message: '请输入费率', trigger: 'blur' },
        ],
        onlineChannelBusiness:[
          { required: true, message: '请输入渠道商', trigger: 'change' },
        ],
        onlineSort:[
          { required: true, message: '请输入排序', trigger: 'blur' },
        ],
        icon:[
          { required: true, message: '请上传icon', trigger: 'change' },
        ],
        qrPic:[
          { required: true, message: '请上传充币二维码', trigger: 'change' },
        ],
        // 电汇的方式
        bankName: [
          { required: true, message: '请输入Bank Name', trigger: 'blur' },
        ],
        accountName: [
          { required: true, message: '请输入Account Name', trigger: 'blur' },
        ],
        BankNumberOrAccount: [
          { required: true, message: '请输入Bank Number Or Account', trigger: 'blur' },
        ],
        bankAddress: [
          { required: true, message: '请输入bank Address', trigger: 'blur' },
        ],
        swiftCode: [
          { required: true, message: '请输入swiftCode', trigger: 'blur' },
        ],
        desc: [
          { required: true, message: '请输入', trigger: 'change' },
        ],
        descHant: [
          { required: true, message: '请输入', trigger: 'change' },
        ],
        descEn: [
          { required: true, message: '请输入', trigger: 'change' },
        ],
      },
      limitMaxOption:[
        {
          label: '不限',
          value: 1
        },
        {
          label: '限制',
          value: 2
        },
      ],
      payWayOptions:[],
      limitMax: 1,
      onlineChannelBusinessOption:[]
    }
  },
  created(){
    if(this.form.type){
      this.getSelectChannelInfoList();
    }
    this.getWithdrawList();
  },
  computed:{
    ...mapGetters([]),
    // 增加了一个支付方式为电子钱包: perfectmoney归到电子钱包
    onlinePayWayFlag(){
      return this.form.payWay == 1 || this.form.payWay == 4 || this.form.payWay == 10 || this.form.payWay == 11;
    },
    payWayOptionsComputed(){
      if(this.payWayOptions.length){
        if(this.form.type == 1){
          return this.payWayOptions.filter((item) => {
            return item.oprType == 1;
          });
        }else if(this.form.type == 2){
          return this.payWayOptions.filter((item) => {
            return item.oprType == 2 && item.id == 4;
          });
        }else if(this.form.type == 3){
          return this.payWayOptions.filter((item) => {
            return item.oprType == 3 && item.id == 7;
          })
        }
      }else {
        return [];
      }
    },
    typeDisabled(){
      return !this.isAdd ? true: false;
    },
    rateFlag(){
      return this.form.payWay == 1 || this.form.payWay == 4 || this.form.payWay == 7 || this.form.payWay == 10 || this.form.payWay == 11 || (this.form.payWay == 3 && this.form.sourceType == 1);
    }
  },
  watch:{
    payInfo:{
      handler(newValue){
        if(Object.keys(newValue).length > 0 && this.isAdd == false){
          this.assignmentFormCom(newValue);
        }
      },
      deep: true,
      immediate: true,
    },
    dialogShow(newValue){
      this.$nextTick(() => {
        if(newValue && this.isAdd){
          this.clearForm();
          this.getSelectChannelInfoList();
          this.$refs.form && this.$refs.form.clearValidate();
        }
        if(newValue && !this.isAdd){
          this.$refs.form && this.$refs.form.clearValidate();
        }
      });
    },
    'form.type'(newValue){
      if(newValue){
        this.getSelectChannelInfoList();
      }
      if(newValue == '2' ){
        this.form.payWay = 4;
      }else if(newValue == '3'){
        this.form.payWay = 7;
      }else {
        this.form.payWay = 1;
      }
    },
    'form.payWay'(newValue){
      this.$refs.form && this.$refs.form.clearValidate(['onlineRate','onlineChannelBusiness','onlineSort','bankName','accountName','BankNumberOrAccount','bankAddress','swiftCode','qrcode','usdtAdress','linkQrcode','linkAdress']);
    },
    'form.currency'(newValue){
      if(newValue){
        if(this.form.payWay == 1 || this.form.payWay == 4){
          if(this.getPaymentCurrencyList().length > 0){
            let innerIndex = this.getPaymentCurrencyList().findIndex((item) => {
              return item.currency == newValue;
            });
            if(innerIndex > -1){
              this.exRate = this.getPaymentCurrencyList()[innerIndex].exRate;
            }
          }
        }
      }
    },
  },
  methods: {
    getLimitUploadImageFormat,
    getWithdrawList(){
      getPaymentList({
        status: 0,
      }).then((res) => {
        this.payWayOptions = res.content;
      });
    },
    getSelectChannelInfoList(){
      let params = {
        oprType: this.form.type == '1' ? 1: 2,
      }

      queryChannelInfoList(params).then((res) => {
        this.channelInfoList = res;
        if(this.isAdd){
          this.form.onlineChannelBusiness = this.channelInfoList[0].payPlatformName;
          this.form.currency = this.getPaymentCurrencyList()[0].currency;
        }
      })
    },
    channelNameEnInputHandle(){
      this.form.channelNameEn = limitEnAndSpace(this.form.channelNameEn);
    },
    clearForm(){
      this.form = {
        id: '',
        channelName: '',
        channelNameHant: '',
        channelNameEn: '',
        lang: 'all',
        status: '1',
        type: '1',
        isAutomaticEntry: '1',
        minMoney: '',
        currency: '',
        maxMoney: '',
        availableTime: ['08:00:00','23:59:59'],
        payWay: 1,

        // 在线支付的条件下
        onlineRate: '',
        onlineChannelBusiness: '',
        riskControlFlag: 0,  //默认勾选不限制
        onlineSort: '',
        icon: '',

        // 电汇支付的条件下
        bankName: '',
        accountName: '',
        BankNumberOrAccount: '',
        bankAddress: '',
        swiftCode: '',
        // USDT 支付
        sourceType: 1,
        address: '',
        qrPic: '',
        desc: '',
        descEn: '',
        descHant: '',
      };
      this.$refs.form.resetFields();
    },
    cancel() {
      this.close();
    },
    close(){
      this.$emit('close-dialog');
    },
    beforeCloseHandle(){
      this.close();
    },
    catchData(value) {
      //在这里接受子组件传过来的参数，赋值给data里的参数
      this.form.desc = value
    },
    catchDataHant(value){
      this.form.descHant = value;
    },
    catchDataEn(value){
      this.form.descEn = value;
    },
    assignmentFormCom(data){
      this.form.id = data.id;
      this.form.channelName = data.channelName;
      this.form.channelNameHant = data.channelNameHant;
      this.form.channelNameEn = data.channelNameEn;
      this.form.lang = data.lang;
      this.form.status = data.status + '';
      this.form.type = data.oprType + '';

      this.form.isAutomaticEntry = data.isAutomaticEntry + '';
      this.form.minMoney = data.minimumLimit;
      this.form.currency = data.currency;
      this.form.maxMoney = data.maximumLimit;

      if(this.form.type == 1 || this.form.type == 2){
        this.form.riskControlFlag = data.riskControlFlag;
      }

      if(this.form.maxMoney == 9999999999.00){
        this.limitMax = 1;
      }else {
        this.limitMax = 2;
      };
      if(data.availableStart){
        this.$set(this.form,'availableTime',[]);
        this.$set(this.form,'availableTime',[data.availableStart,data.availableEnd]);
      }else {
        this.$set(this.form,'availableTime',[]);
      }

      this.form.payWay = data.payType;

      // 在线支付
      this.form.onlineRate = data.feeValue;
      this.form.onlineChannelBusiness = data.payPlatformName;
      this.form.onlineSort = data.channelOrder;
      this.form.icon = data.logoUrl;
      // 电汇
      this.form.bankName = data.bankName;
      this.form.accountName = data.accountName;
      this.form.BankNumberOrAccount = data.bankNumberAccount;
      this.form.bankAddress = data.bankAddress;
      this.form.swiftCode = data.swiftCode;
      // usdt
      this.form.sourceType = data.sourceType || 1;
      this.form.address = data.address;
      this.form.qrPic = data.qrPic;
      this.form.desc = data.desc;
      this.form.descEn = data.descEn;
      this.form.descHant = data.descHant;
    },
    doSubmit() {
      this.$refs.form.validate((valid) => {
        if(valid){
          if (this.isAdd) {
            this.doAdd();
          } else this.doEdit();
        }else {
          console.log('error submit!!');
          return false;
        }
      })

    },
    doAdd() {
      addPayFee(this.handleAddOrEditInterfaceParams()).then(res => {
        // this.resetForm()
        this.$notify({
          title: '添加成功',
          type: 'success',
          duration: 2500
        });
        this.loading = false;
        this.$parent.init();
        this.close();
      }).catch(err => {
        this.loading = false
        console.log(err.response.data.message)
      })
    },
    doEdit() {
      let editParams = this.handleAddOrEditInterfaceParams();
      editParams.id = this.form.id;
      editPayFee(editParams).then(res => {

        this.$notify({
          title: '修改成功',
          type: 'success',
          duration: 2500
        });
        this.$parent.init();
        this.close();
        this.resetForm()
      }).catch(err => {
        this.loading = false;
        console.log(err)
      })
    },
    resetForm() {
      this.$refs.form && this.$refs.form.clearValidate();
      this.$refs.form && this.$refs.form.resetFields();
    },
    handleSuccessIcon(item){
      this.$refs.iconurl.clearValidate();
      getBase64(item.file).then(res => {
        let file = base64toFile(res);
        uploadFile(file).then((res) => {
          this.form.icon = res.data.content[0].url;
        });
      });
    },
    beforeAvatarUpload(file) {
      return limitImageSizeFormat(file,this);
    },
    handleSuccessQrPic(item){
      this.$refs.qrpic.clearValidate();
      getBase64(item.file).then(res => {
        let file = base64toFile(res);
        uploadFile(file).then((res) => {
          this.form.qrPic = res.data.content[0].url;
        });
      });
    },
    getPaymentCurrencyList(){
      if(this.form.onlineChannelBusiness){
        let channelIndex = this.channelInfoList.findIndex((item) => {
          return item.payPlatformName == this.form.onlineChannelBusiness;
        });
        if(channelIndex > - 1){
          return this.channelInfoList[channelIndex].currencyList;
        }else {
          return [];
        }
      }else {
        return [];
      }
    },
    onlineChannelBusinessChangeHandle(val){
      this.form.currency = this.getPaymentCurrencyList()[0].currency;
    },
    limitMaxChangeHandle(val){
      if(val === 1){
        this.$refs.form.clearValidate(['maxMoney']);
      }
    },
    getIconShow(){
      return this.form.payWay == 1 || this.form.payWay == 3 || this.form.payWay == 10 || this.form.payWay == 11;
    },
    handleAddOrEditInterfaceParams(){
      let params = {
        channelName: this.form.channelName,
        channelNameHant: this.form.channelNameHant,
        channelNameEn: this.form.channelNameEn,
        lang: this.form.lang,
        status: Number(this.form.status),
        oprType: Number(this.form.type),
        isAutomaticEntry: this.form.isAutomaticEntry - 0 ,
        minimumLimit: this.form.minMoney,
        maximumLimit: this.limitMax == 1 ? 9999999999.00 : this.form.maxMoney,
        currency: this.form.currency,
        availableStart: this.form.availableTime[0],
        availableEnd: this.form.availableTime[1],
        payType: this.form.payWay,
      };
      if(params.oprType == 1 || params.oprType == 2){
        params.riskControlFlag = this.form.riskControlFlag;
      }
      // 需要整理判断,减少判断  todo 有就传?
      // icon传递
      if(this.form.payWay == 1 || this.form.payWay == 3 || this.form.payWay == 10 || this.form.payWay == 11){
        params.logoUrl = this.form.icon;
      }
      // 排序
      if(this.form.payWay == 1 || this.form.payWay == 4 || this.form.payWay == 7 || this.form.payWay == 3 || this.form.payWay == 10 || this.form.payWay == 11){
        params.channelOrder = this.form.onlineSort;
      }
      if(this.form.payWay == 1 || this.form.payWay == 4 || this.form.payWay == 7 || this.form.payWay == 10 || this.form.payWay == 11 || (this.form.payWay == 3 && this.form.sourceType == 1) ){
        // 在线支付
        params.payPlatformName = this.form.onlineChannelBusiness;
      }

      if(this.form.payWay == 1 || this.form.payWay == 4 || this.form.payWay == 7 || this.form.payWay == 10 || this.form.payWay == 11){
        // 在线支付
        params.feeValue = this.form.onlineRate;
        params.payPlatformName = this.form.onlineChannelBusiness;
      }else if(this.form.payWay == 2){
        params.bankName = this.form.bankName;
        params.accountName = this.form.accountName;
        params.bankNumberAccount = this.form.BankNumberOrAccount;
        params.bankAddress = this.form.bankAddress;
        params.swiftCode = this.form.swiftCode;
      }else if(this.form.payWay == 3){
        params.sourceType = this.form.sourceType;
        params.address = this.form.address;
        params.qrPic = this.form.qrPic;
      }
      // usdt的第三方
      if(this.form.payWay == 3 && this.form.sourceType == 1){
        params.feeValue = this.form.onlineRate;
      }
      // 渠道说明
      if(this.form.desc){
        params.desc = this.form.desc;
      }
      if(this.form.descEn){
        params.descEn = this.form.descEn;
      }
      if(this.form.descHant){
        params.descHant = this.form.descHant;
      }
      return params;
    }
  }
}
</script>
<style lang="less">
  .pay-add-or-edit-dialog {
    .el-dialog__body {
      padding-left: 30px;
      padding-right: 30px;
    }
    .min-money {
      width: 300px;
    }
    .max-money {
      width: 100%;
    }
    .currency-min-select {
      width: 100px;
    }
    .currency-max-select {
      width: 80px;
    }
    .currency-max-select {
      .el-form-item__error {
        left: 100px!important;
      }
    }
  }
</style>
<style lang="less" scoped>
.pay-add-or-edit-dialog {
  .money-prefix {
    width: 80px;
  }
  .time-box {
    width: 100%;
  }
  .time-spilt {
    display: inline-block;
    width: 100%;
    text-align: center;
  }
  .input-box-rate {
    width: 380px;
    position: relative;
    .ratesuffix {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 20px;
    }
  }
  .upload-img {
    width: 50px;
    height: 50px;
  }
  .uppload-btn {
    color: skyblue;
  }
  .el-form-item__label {
    word-break: break-all;
  }
  .delete {
    cursor: pointer;
  }
  .icon-class {
    width: 100px;
    margin-top: 20px;
  }
  .qr-pic {
    width: 100px;
    max-height: 300px;
    margin-top: 10px;
  }
}
</style>
